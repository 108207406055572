import React, { memo } from 'react'
import { themed, type IconProps } from '@tamagui/helpers-icon'
import { Svg, Path, SvgProps } from 'react-native-svg'
import { getTokenValue } from '@red-ui/components'

const Icon = (propsBase: IconProps) => {
  const { color, size = getTokenValue('$2', 'size'), ...otherProps } = propsBase as SvgProps & { size: number }

  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...otherProps}>
      <Path d="M0 0h24v24H0z" fill="none" />
      <Path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'Success'

export const SuccessIcon = memo<IconProps>(themed(Icon))
