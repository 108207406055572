import { get, post, patch } from '../utils/httpClient'
import { getRedDataApiPaths } from '../config'

type SubscriptionFrequencyType = 'MONTH' | 'YEAR'

export type SubscriptionFeature = {
  description: string
  extendedDescription?: string
}

export type SubscriptionPlan = {
  id: string
  name: string
  cost: string
  description: string
  featuresDescription: string
  frequency: SubscriptionFrequencyType
  features: SubscriptionFeature[]
  primaryColor: string
  nameTextPrimaryColor: string
}

export type CheckoutSessionResponse = {
  id: string
  clientSecret: string
}

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CANCELED: 'CANCELED',
} as const

export type SubscriptionStatus = (typeof SUBSCRIPTION_STATUS)[keyof typeof SUBSCRIPTION_STATUS]

export type UserSubscription = {
  id: string
  planId: string
  status: SubscriptionStatus
}

export type UserSubscriptionStatusResponse = {
  subscriptions: UserSubscription[]
}

export const SUBSCRIPTION_ERROR_CODE = {
  SUBSCRIPTION_EXISTS: 'SUBSCRIPTION_EXISTS',
} as const

export type SubscriptionErrorCode = (typeof SUBSCRIPTION_ERROR_CODE)[keyof typeof SUBSCRIPTION_ERROR_CODE]

export type SubscriptionError = {
  code: SubscriptionErrorCode
  message: string
}

export type SubscriptionConfirmationResponse = {
  id: string
  planId: string
}

export type CancelSubscriptionRequestBody = {
  subscriptionId: string
  reasonText: string
}

export const apiGetSubscriptionPlans = async (): Promise<SubscriptionPlan[]> => {
  const response = await get(`${getRedDataApiPaths().B2C_API_SUBSCRIPTIONS_PLANS}`, true)
  return response.plans
}

export const apiGetSubscriptionPlan = async (planId: string): Promise<SubscriptionPlan> => {
  return await get(`${getRedDataApiPaths().B2C_API_SUBSCRIPTIONS_PLANS}/${planId}`, true)
}

export const apiCreateStripeCheckoutSession = async (planId: string): Promise<CheckoutSessionResponse> => {
  return post(`${getRedDataApiPaths().B2C_API_STRIPE_CHECKOUT_SESSION}`, true, false, { planId })
}

export const apiGetUserSubscriptionStatus = async (): Promise<UserSubscriptionStatusResponse> => {
  return get(`${getRedDataApiPaths().B2C_API_USER_SUBSCRIPTIONS}`, true)
}

export const apiSubscribeToPlan = async (checkoutSessionId: string): Promise<SubscriptionConfirmationResponse> => {
  return post(`${getRedDataApiPaths().B2C_API_USER_SUBSCRIPTIONS}`, true, false, { checkoutSessionId })
}

export const apiCancelUserSubscription = async ({ subscriptionId, reasonText }: CancelSubscriptionRequestBody) => {
  return patch(`${getRedDataApiPaths().B2C_API_USER_SUBSCRIPTIONS}/${subscriptionId}`, true, false, {
    reasonText,
    status: SUBSCRIPTION_STATUS.CANCELED,
  })
}
