export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS'
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE'

export const LINK_ACCOUNTS = 'LINK_ACCOUNTS'
export const LINK_ACCOUNTS_SUCCESS = 'LINK_ACCOUNTS_SUCCESS'
export const LINK_ACCOUNTS_FAILURE = 'LINK_ACCOUNTS_FAILURE'
export const AUTO_LINK_ACCOUNTS = 'AUTO_LINK_ACCOUNTS'
export const AUTO_LINK_ACCOUNTS_SUCCESS = 'AUTO_LINK_ACCOUNTS_SUCCESS'
export const AUTO_LINK_ACCOUNTS_FAILURE = 'AUTO_LINK_ACCOUNTS_FAILURE'
export const START_LINK_ACCOUNTS_FLOW = 'START_LINK_ACCOUNTS_FLOW'
export const PARTNER_LINK_VAA = 'PARTNER_LINK_VAA'

export const LINK_ACCOUNTS_REQUEST_FIRED = 'LINK_ACCOUNTS_REQUEST_FIRED'
export const LINK_ACCOUNTS_REQUEST_SUCCESS = 'LINK_ACCOUNTS_REQUEST_SUCCESS'

export interface AccountsState {
  data: { entities: { [id: string]: Account }; ids: string[] }
  pendingLinking?: boolean
  isLoading?: boolean
  isUpdating?: boolean
  isAutoLinking?: boolean
  inAutoLinkingFlow?: boolean
  connectSuccessUrl?: string | null
  connectFailUrl?: string | null
  autoLinkComplete?: boolean
  partnerLinkVAASuccessUrl?: string | null
  partnerLinkVAAFailUrl?: string | null
  errorMessage: string | null
}

export interface Account {
  partnerCode: 'VRED' | 'VAA' | 'SYF'
  partnerUserId: string
}

export interface AccountResponse {
  accounts: Account[]
}

export interface PartnerConnectRequestBody {
  partnerUserIdToken: string
}

export interface PartnerConnectResponseBody {
  accountId: string
  partnerCustomerId: string
  partnerCode: string
}
