import React from 'react'
import {
  DynamicAccordionsBlock,
  DynamicContentFactory as DynamicContentFactoryBase,
  DynamicLayout,
  DynamicPlaceholder,
  DynamicRichText,
  YStack,
} from '@red-ui/components'
import { ContentfulEntry } from '@vrw/data/src/utils/contentfulPages'

import { DynamicButton } from './DynamicButton'
import { DynamicCarousel } from './DynamicCarousel'
import { RichTextRenderer as RichTextRendererWeb } from './DynamicRichText/RichTextRenderer'

type ComponentCreator = (content: ContentfulEntry) => JSX.Element
type FactoryConfig = Record<string, ComponentCreator | undefined>

const createDynamicPageContent: ComponentCreator = (content) => (
  <YStack>
    {content.fields.sections?.map((section: ContentfulEntry) => {
      const sectionType = section.sys.contentType.sys.id
      return sectionType === 'dynamicLayout' ? (
        <DynamicLayout key={section.sys.id} fields={section.fields} isSection>
          {section.fields.content?.map((x: ContentfulEntry) => <DynamicContentFactory key={x.sys.id} content={x} />)}
        </DynamicLayout>
      ) : (
        <DynamicContentFactory key={section.sys.id} content={section} />
      )
    })}
  </YStack>
)

const createDynamicLayout: ComponentCreator = (content) => (
  <DynamicLayout fields={content.fields}>
    {content.fields.content?.map((x: ContentfulEntry) => <DynamicContentFactory key={x.sys.id} content={x} />)}
  </DynamicLayout>
)

const createDynamicRichText: ComponentCreator = (content) => (
  <DynamicRichText fields={content.fields} RichTextRenderer={RichTextRendererWeb} />
)
const createDynamicButton: ComponentCreator = (content) => <DynamicButton fields={content.fields} />
const createHomePageFaqSection: ComponentCreator = (content) => (
  <DynamicAccordionsBlock fields={content.fields} RichTextRenderer={RichTextRendererWeb} />
)

const dynamicComponentFactory: FactoryConfig = {
  dynamicPageContent: createDynamicPageContent,
  dynamicLayout: createDynamicLayout,
  dynamicRichText: createDynamicRichText,
  dynamicButton: createDynamicButton,
  homePageFaqSection: createHomePageFaqSection,
  blockIntroCarousel: DynamicCarousel,
}

export const DynamicContentFactory = ({
  content,
  config = dynamicComponentFactory,
  placeholderComponent = <DynamicPlaceholder content={content} />,
}: {
  content: ContentfulEntry
  config?: FactoryConfig
  placeholderComponent?: JSX.Element
}) => <DynamicContentFactoryBase content={content} config={config} placeholderComponent={placeholderComponent} />
