import { useQuery, useQueryClient } from '@tanstack/react-query'
import { apiGetAccounts } from '@vrw/data/src/api/accounts.api'
import { getProfileData } from '@vrw/data/src/redux/profile/selectors'
import { useAppSelector } from '../../redux/hooks/hooks'
import { Profile } from '@vrw/data/src/redux/profile/types'

export const accountsQueryKeys = {
  all: () => ['accounts'] as const,
  user: (userId: Profile['redUserId'] | undefined) => [...accountsQueryKeys.all(), userId],
}

export const useAccounts = () => {
  const { redUserId } = useAppSelector(getProfileData) || {}
  return useQuery({
    queryKey: accountsQueryKeys.user(redUserId),
    queryFn: apiGetAccounts,
    enabled: !!redUserId,
  })
}

export const usePrefetchAccounts = () => {
  const queryClient = useQueryClient()
  const { redUserId } = useAppSelector(getProfileData) || {}
  return redUserId && queryClient.prefetchQuery({ queryKey: accountsQueryKeys.user(redUserId), queryFn: apiGetAccounts })
}
