import React, { ReactNode } from 'react'
import { Card, CardProps, H6, Image, Paragraph, XStack, YStack } from '@red-ui/components'
import { SuccessIcon } from '../icons/SuccessIcon'

interface AccountCardProps extends CardProps {
  isCardLinked: boolean
  backgroundUri: string
  logoUrl: string
  partnerName: string
  message: string
  partnerUserIdLabel?: string
  partnerUserId?: string
  buttonComponent?: ReactNode
  hideFooter?: boolean
}

export const AccountCard = ({
  isCardLinked,
  backgroundUri,
  logoUrl,
  partnerName,
  message,
  partnerUserIdLabel,
  partnerUserId,
  buttonComponent,
  hideFooter,
  ...props
}: AccountCardProps) => (
  <Card size="$4" borderRadius="$6" flex={1} height={300} padding="$2" marginHorizontal="$2" overflow="hidden" {...props}>
    <Card.Header padding="$0" height={hideFooter ? '100%' : 'unset'} justifyContent={hideFooter ? 'center' : 'unset'}>
      <Image
        marginBottom="$2"
        source={{
          width: 84,
          height: 84,
          uri: logoUrl,
        }}
        alignSelf="center"
        objectFit="contain"
        resizeMode="contain"
      />
      <H6 color="$white" fontWeight="700" lineHeight="$6" textAlign="center" marginBottom="$1">
        {partnerName}
      </H6>
      <XStack alignItems="center" justifyContent="center" height={38}>
        {isCardLinked && <SuccessIcon testID="success-icon" size="$2" color="white" marginRight="$1" />}
        <Paragraph color="$white" fontSize="$3" fontWeight="700">
          {message}
        </Paragraph>
      </XStack>
    </Card.Header>
    {!hideFooter && (
      <Card.Footer padding="$0">
        {isCardLinked && partnerUserIdLabel && partnerUserId ? (
          <YStack
            flex={1}
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(255, 255, 255, 0.2)"
            borderRadius={8}
            padding={8}>
            <Paragraph color="$white" textAlign="center">
              {partnerUserIdLabel}
            </Paragraph>
            <Paragraph color="$white" textAlign="center">
              {partnerUserId}
            </Paragraph>
          </YStack>
        ) : (
          !isCardLinked && buttonComponent
        )}
      </Card.Footer>
    )}
    <Card.Background>
      <Image
        source={{
          width: 400,
          height: 300,
          uri: backgroundUri,
        }}
        alignSelf="center"
        objectFit="cover"
        resizeMode="cover"
      />
    </Card.Background>
  </Card>
)
